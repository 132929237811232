* {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #18191f;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

@-moz-document url-prefix() {
  * {
    font-weight: 300;
  }
}

a {
    text-decoration: none;
    color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*GOOGLE MAPS*/

.pac-container {
  border-radius: 8px;
  border: 1px solid #eaecef;
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.08);
  padding: 16px 8px 0;
  z-index: 10000;
  transform: scale(calc(14 / 16));
  transform-origin: left;
  margin-top: -16px;
}
.pac-item {
  margin: 0;
  border: none;
  padding: 11px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  font-size: 16px;
}

.pac-item span {
  font-size: 16px;
}

.pac-item:hover {
  background: #f7f8f9;
}
.pac-icon::before {
  content: none;
}
.gm-style-iw.gm-style-iw-c {
  background-color: #fff !important;
}
.gm-style-iw-d {
  overflow: scroll !important;
  padding: 0 !important;
}
.gm-style-iw.gm-style-iw-c .gm-ui-hover-effect {
  display: block !important;
}
.gm-style .gm-iw.gm-transit {
  margin-left: 23px !important;
}

/* MONTH CALENDAR */
.react-datepicker {
  border: none;
}
.react-datepicker-popper {
  z-index: 10 !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
  width: 100%;
}
.react-datepicker__header,
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  padding: 0;
  border: none;
  background-color: #fff;
}
.react-datepicker__month {
  margin: 0px 8px 8px !important;
  border-radius: 12px;
  border: 1px solid #eaecef;
  width: 238px;
  height: 169px;
  display: flex;
  flex-direction: column;
}
.react-datepicker__month-text {
  margin: 4px !important;
  /*width: auto !important;*/
  width: calc(100% / 3) !important;
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.react-datepicker__month-wrapper {
  height: calc(100% / 4);
  display: flex;
}

.react-datepicker__month-text:after {
  content: "";
  display: block;
  height: calc(100% + 8px);
  width: 1px;
  position: absolute;
  top: -4px;
  right: -4px;
  background: #eaecef;
}

.react-datepicker__month-text:nth-child(3n):after {
  content: none;
}

.react-datepicker__month-text--keyboard-selected {
  background: white !important;
}

.react-datepicker__month-text--keyboard-selected:not(
    .react-datepicker__month--selected
  ):not(.react-datepicker__month--disabled) {
  color: #18191f;
}

.react-datepicker__month--selected, .react-datepicker__month-text--selected {
  background-color: #ed713c !important;
  border-radius: 8px !important;
}
.react-datepicker__month-wrapper {
  border-bottom: 1px solid #eaecef;
}

.react-datepicker__month-wrapper:last-child {
  border-bottom: none;
}

@media (max-width: 600px) {
  .react-datepicker__month-container {
    width: 100%;
    height: 100%;
  }
  .react-datepicker__month {
    margin: 0px 16px 16px !important;
    height: calc(100% - 58px);
    text-align: center;
    width: auto;
  }
  .react-datepicker__month-text {
    margin: 8px !important;
  }
  .react-datepicker__month-text:after {
    height: calc(100% + 16px);
    top: -8px;
    right: -8px;
  }
  .react-datepicker__month-text:nth-child(3n):after {
    content: none;
  }
}

.react-datepicker__month--disabled {
  color: #ccc !important;
}
